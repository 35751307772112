body{
    background-image: url("/img/frontend/background.jpg");
    background-size: cover;
    height: 100vh;

    #app {
        height: 100%; 
        display: flex;
        justify-content: center; 
        align-items: center;
    }

}
.form-group {
    .btn {
        background-color: $primary-color;
        color:#fff;
        border-radius:5px;
        border:transparent solid 2px;
        box-shadow: none;
        &:hover {
            background-color: #fff;
            border: $primary-color solid 2px;
            color: $primary-color;
        }
    }
}


.card-header {
    span {
        font-family: $font-family-alt;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 20px;
    }
}