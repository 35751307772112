// Plugins

// FontAwesome 5
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/brands";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";
/*@import "~jquery-ui/themes/base/core";
@import "~jquery-ui/themes/base/theme";
@import "~jquery-ui/themes/base/datepicker";*/

@import url('https://fonts.googleapis.com/css2?family=Aleo:wght@300;400;700&display=swap');
@import url("https://use.typekit.net/rvf6wbm.css");

// Header Styles
.demo, .logged-in-as {
  margin: 0 !important;
  border-radius: 0 !important;
}

.loading{
  height:100px;
  max-width: 200px;
  width: 80%;
  position: absolute;
  margin:auto;
  left:0;
  right:0;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  top:36%;
}

